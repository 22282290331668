import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import headergif from "../../assets/images/bg/headergif.gif";
import Illustration from "../../assets/images/home/home-page-illustration.png";
import centerimage from "../../assets/images/home/centerImage.png";
import Footer from "../../assets/images/bg/footer-img.png";
import buttonimg from "../../assets/images/home/Button-img.png";
import "./Home.scss";
import { ROUTES } from "../../lib/consts";
import API from "../../api";
import { Trans, useTranslation } from "react-i18next";
import { useGlobalModalContext } from "../../helpers/GlobalModal";
import { useEffect, useRef, useState } from "react";
import { LANGUAGE, LANGUAGES } from "../../lib/consts";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  addCaptchaScript,
  gtagTrackEvent,
  GA_EVENTS,
  hideCaptchaBadge,
  showCaptchaBadge,
} from "../../lib/utils";

declare var grecaptcha: any;

const Home = () => {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      // If the user is logged in, redirect to payoutform
      // navigate(ROUTES.SELECT_PAYOUT);
      navigate(ROUTES.HOME);
    } else {
      addCaptchaScript(() => {
        showCaptchaBadge();
      });
    }
  }, [isLoggedIn]);
  useEffect(
    () => () => {
      hideCaptchaBadge();
    },
    []
  );

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     const handleLocationChange = () => {
  //       // Perform your back navigation logic here
  //       navigate(ROUTES.PAYOUT_SELECT); // Change this to the appropriate route
  //     };

  //     window.addEventListener("popstate", handleLocationChange);

  //     return () => {
  //       window.removeEventListener("popstate", handleLocationChange);
  //     };
  //   }
  // }, [navigate, location.pathname, isLoggedIn]);

  useEffect(() => {
    gtagTrackEvent(GA_EVENTS.LP_pageview);
  }, []);

  const getStarted = () => {
    navigate(ROUTES.REGISTER);
    gtagTrackEvent(GA_EVENTS.Encash_Now);

    // API.getStarted("get_started")
    //   .then((res) => {
    //     // navigate(ROUTES.REGISTER);
    //     gtagTrackEvent(GA_EVENTS.Get_Started);
    //   })
    //   .catch((error) => {
    //     setShowError(true); // Set showError to true for UI feedback
    //     console.error("Error in API.getStarted:", error);

    //     if (error.code === 401) {
    //       // Session not found, handle as needed
    //       console.error("User not authenticated:", error.message);
    //     }
    //   });
  };

  return (
    <div className="home-page">
      <div className="content">
        <img src={headergif} alt="headergif" className="headergif" />
        <img src={Logo} alt="logo" className="logo" />
        {/* <img src={Illustration} alt="illustration" className="illustration" /> */}
        <p className="color-text">
          Ting-ting-
          <br />
          cha-ching!
        </p>
        <p className="color-text2">
          {" "}
          Found a Bank of Small Wins note <br />
          in your pocket?
        </p>
        <div className="text-wrapper">
          {/*<img src={centerimage} alt="centerimg" className="centerimage" />*/}
        </div>
        <div className="content-wrapper">
          {/*<select
            className="language-selection header-lang"
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
          >
            <option key={""} value={""}>
              CHOOSE LANGUAGE
            </option>
            {Object.keys(LANGUAGES).map((key) => {
              if (i18n.language === key) {
                return (
                  <option key={key} value={key} selected>
                    {LANGUAGES[key as LANGUAGE]}
                  </option>
                );
              }

              return (
                <option key={key} value={key}>
                  {LANGUAGES[key as LANGUAGE]}
                </option>
              );
            })}
          </select>*/}

          <div className="get-started-container" onClick={getStarted}>
            <img
              src={buttonimg}
              alt="get-started"
              className="get-started-image"
            />
            <span className="get-started-text">Encash now</span>
          </div>
        </div>
      </div>
      <img src={Footer} alt="footer" className="footer-img" />
    </div>
  );
};

export default Home;
